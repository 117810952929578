<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-10" id="page-login">
    <div class="vx-col">
      <div class="vx-row no-gutter justify-center items-center">
        <div class="vx-col lg:block">
          <center>
            <img src="@/assets/logo.png" alt="logo" class="logo mx-auto lg:w-1/4 mb-10" />
          </center>
        </div>


        <div class="vx-col mx-auto lg:w-3/4">
          <div class="p-8 login-tabs-container">

            <close-button url="/portfolio"/>

            <div class="mb-4 float-right mt-5">
              <h1 class="text-4xl oeno_portfolio_title">Manage Payments</h1>
            </div>

            <hr class="oeno_divider" />

            <span id="download_loader" v-if="!$store.state.portfolio"></span>

            <div class="grid grid-cols-5 gap-4">
              <div class="col-span-4">
                <span class="oeno_portfolio_winetitle">Current Balance</span>
              </div>
              <div class="col-span-1">
                <span class="oeno_portfolio_winetitle float-right">{{ $store.state.user.currency }}
                  {{ currencyFormatter(getBalance) }}</span>
              </div>

              <div class="col-span-5">
                <hr class="oeno_divider_light" />
              </div>
              <div class="col-span-2 mt-6">
                <span class="oeno_portfolio_winetitle">Add {{ currency }} funds to your account</span>
              </div>
              <div class="col-span-2">
                <md-field>
                  <select v-model="currency">
                    <!-- <option>EUR</option> -->
                    <option>GBP</option>
                  </select><md-input v-model="amount" @keyup="validateAmout()" @change="validateAmout()"
                    type="number"></md-input>

                </md-field>
                <span v-if="!validData" class="errortext">The amount of funds to be added needs to greater than
                  {{ currency }} 1</span>

              </div>
              <div class="col-span-1 mt-2">
                <md-button @click="requestAddfunds()" class="float-right oeno-button-sm"
                  :disabled="!validData || processing">{{ processing ? 'Processing' : 'Proceed' }}</md-button>
              </div>
              <div class="col-span-5">
                <hr class="oeno_divider_light" />
              </div>

              <view-payments :payments="$store.state.user.payments" class="col-span-5" />

            </div>



          </div>
        </div>
      </div>

      <footer-bar />

    </div>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";

import CloseButton from "../../../components/pageparts/CloseButton.vue"
import ViewPayments from "../../../components/payments/ViewPayments.vue"
import FooterBar from "../../../components/pageparts/Footer.vue"


export default {
  data() {
    return {
      amount: 1,
      currency: 'GBP',
      processing: false,
      validData: true
    };
  },
  components: {
    CloseButton,
    ViewPayments,
    FooterBar
  },
  mounted() {
  },
  computed: {
    getBalance() {
      var balance = 1;
      if (this.$store.state.user) {
        balance = this.$store.state.user.balance_on_account;
      }
      return this.currencyFormatter(balance);
    },
  },
  methods: {
    requestAddfunds() {
      this.processing = true;

      // Call the backend service and get the volt redirect URL
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP
        .post("/api/add-funds", { amount: this.amount, currency: this.currency }, { headers: headers })
        .then(response => {
          if (response.data.statuscode == 200) {
            this.updateProfile();

            this.processing = false;

            window.location.href = response.data.data;

          }
        }
        ).catch(error => {

          this.processing = false;

          this.$notify({
            group: 'oeno',
            title: 'Add Funds Error',
            text: 'Sorry there seems to have been an error processing your request, please try again later.',
            type: 'error',
          });

          console.log(error);

        });

    },
    validateAmout() {
      if (parseFloat(this.amount) < 1) {
        this.validData = false;
      } else {
        this.validData = true;
      }
    }
  },
};
</script>

<style>

</style>
