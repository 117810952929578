<template>

<div class="vx-col mx-auto lg:w-3/4">
        <div class="p-8 login-tabs-container">

          <hr class="oeno_divider mt-50" />

          <div class="grid grid-cols-6 gap-4">
            <div class="col-span-2 oeno_portfolio_footer_text">
              Company Reg No. 09637864<br />
              VAT No. GB 234 3930 18<br />
              AWRS Reg No. XPAW 000 0010 8283<br />
              <a href="https://oenogroup.com/privacy-policy/" target="_new">Privacy Policy</a><br />

            </div>
            <div class="col-span-2 oeno_portfolio_footer_text">
              <center>
                <img src="@/assets/oeno-icon.png" alt="logo" class="w-10" />
              </center>
            </div>
            <div class="col-span-2 oeno_portfolio_footer_text text-right">
              <a href="https://oenogroup.com/contact/" target="_new">Contact Us</a><br />
              &copy; 2022 OenoFuture Ltd. All Rights Reserved.<br />
              You must be 18 or over to order.<br />

            </div>
          </div>



        </div>
      </div>    

</template>
   
<script>


export default {
    name: 'Footer',
}
</script>