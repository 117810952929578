

<template>

    <div>

        <span class="oeno_portfolio_winetitle">Historic Payments</span>
        <div v-if="payments.length > 0">

            <table class="w-full mt-10">
                <thead class="border-b ">
                    <tr>
                        <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                            Amount
                        </th>
                        <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                            Funds Type
                        </th>
                        <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="payment in payments" v-bind:key="payment.id"  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td class="px-3 py-3 oeno_portfolio_tabledata content-center">{{payment.currency}} {{currencyFormatter(payment.amount)}}</td>
                        <td class="px-3 py-3 oeno_portfolio_tabledata uppercase content-center">{{formatPaymentType(payment.type)}}</td>
                        <td class="px-3 py-3 oeno_portfolio_tabledata items-center">{{showPaymentStatus(payment)}}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div v-if="payments.length == 0">
            <h1>No payments on account yet</h1>
        </div>
    </div>
</template>
   
<script>

export default {
    name: 'ViewPayments',
    props: {
        payments: Array
    },  
    methods: {
        formatPaymentType(type) {
            return type.replace("_"," ");
        },
        showPaymentStatus(payment) {
            if (payment.pending) {
                return "Pending"
            }
            return ""
        }
    }
}
</script>
