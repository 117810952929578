

<template>

    <button type="button" @click="closeButton()"
        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span class="sr-only">Close menu</span>
        <!-- Heroicon name: outline/x -->
        <svg class="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M6 18L18 6M6 6l12 12" />
        </svg>
    </button>

</template>
   
<script>


export default {
    name: 'CloseButton',
    props: {
        url: {
            type: String,
            default: ''
        }        
    },
    methods: {
        closeButton() {
            if (this.url == '') {
                this.$router.go(-1)
            } else {
                this.$router.push(this.url)
            }
            
        },
    }
}
</script>
